.App {
    /* background-color: #E5E5E5; */
    margin: 0;
    height:100%;
    top: 0;
    padding: 1.3% 4%;
}

.app-header {
    width: 100%;
    height: 10%;
    background-color: lightgray;
}

.app-sidebar {
    width: 100%;
    height: 10%;
    background-color: indigo;
}