.assembly-comfirmation {
    /* padding: 3.9%; */
    height: 100%;
    font-size: 20px;
    width: 100%;
    /* background: #00173D; */
}

.assembly-comfirmation-body {
    /* height: 75%; */
    overflow: scroll;
    padding-top: 20%;
}

.assembly-comfirmation-save-button-container {
    height: 5.5%;
    min-height: 46px;
    position: fixed;
    /* top: 55%; */
    bottom: 4%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
}

.part-number-info {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

.number {
    font-size: 12px;
    line-height: 20px;
}

.part-detail-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10%;
    margin-bottom: 20%;
}

.part-details {
    flex: 50%;
    margin-bottom: 8%;
}

.part-info-name {
    font-size: 14px;
    line-height: 28px;
}

.part-info-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
}

.save-button {
    width: 100%;
    height: 100%;
    min-height: 42px;
    background: #448AFF;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

