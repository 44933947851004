.qr-scanner {
    /* padding: 3.9%; */
    height: 100%;
    font-size: 20px;
    min-height: 64px;
    /* margin-bottom: 15%; */
}

.qr-scanner-header {
    height: 8.8%;
    /* background-color: green; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6.8%;
    font-weight: 400;
}

.qr-scanner-body {
    padding-top: 2.84%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    z-index: 100;
    margin-bottom: 20%;
}

.qr-input-container {
    min-height: 42px;
}

.input-box {
    border: 1px solid #D5D8DD;
    box-sizing: border-box;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 8px;
    font-size: 18px;
    color: #00173D;
    min-height: 60px;
}

.input-icons {
    position: absolute;
    
    
    /* delete 
    top: 10px;
    left: 10px; */
    /* align-items: center; */
    /* left: auto; right: 10px; */
    /* bottom: 30px; */

    /* display: inline-block;
    right: 0%;
    display: flex;
    gap: 50%;
    justify-content: flex-end; */
    /* top: 0px;
    padding: 5% 5% 4% 5%; */


    position: relative;
    float: right;
    bottom: 40px;
    right: 20px;
    display: flex;
    /* display: flex; */
    gap: 29%;
}

.input-box:focus {
    outline: none !important;
    border:1px solid #448AFF;
    box-shadow: 0 0 10px #719ECE;
}

.height-100 {
    height: 100%;
}

.height-19 {
    height: 19%;
}

.top-30 {
    top: 30%;
}

.top-7 {
    top: 4%;
}