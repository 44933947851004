.web-sidebar {
    position: fixed;
    height: 100%;
    /* background-color: green; */
    background: #FFFFFF;
    width: 6%;
    left: 0;
    top: 0;
    box-shadow: 5px 0px 20px rgba(0, 23, 61, 0.16);
    /* border-radius: 24px 0px 0px 24px; */
}

.web-sidebar-icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 25%; */
}

.web-sidebar-icon {
    padding: 10% 0 10% 0;
    margin-bottom: 50%;
}

.logo-icon {
    padding-top: 25%;
}