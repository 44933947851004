.machine-registration {
    height: 100%;
    font-size: 20px;
    width: 100%;
}

.mrb-mr {
    height: 8%;
    min-height: 56px;
    margin-top: 5%;
}

.machine-registration-body {
    padding-top: 2.84%;
    /* height: 92%; */
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    gap: 2.84%;
    width: 100%;
    margin-top: 15%;
}

.machine-registration-body-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.input-container {
    position: relative;
}

.scanner-icon {
    vertical-align: middle;
}

.save-button-container {
    height: 8.5%;
    min-height: 70px;
    position: fixed;
    /* top: 55%; */
    bottom: 1%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
    z-index: 1000;
}

.registration-save-button {
    width: 100%;
    height: 100%;
    background: #448AFF;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
    min-height: 32px;
}

.button-disabled {
    background: #CCCCCC;
    cursor: none;
    pointer-events: none;
}

.error {
    font-size: 16px;
    color: red;
}

.creation-checkbox-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5%;
    height: 15%;
}

.checkbox-container {
    width: 50%;
}

.creation-checkbox-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-left: 14px;
}

/** for desktop
@media only screen and (min-width: 768px) {
    
    .machine-registration-body {
        padding-top: 5%;
    }
}
*/

@media only screen and (min-width: 810px) {

    /* For desktop: */
    .machine-registration-body {
        margin-top: 15%;
    }
}


