.machine-progress {
    /* height: 100%; */
    font-size: 20px;
    width: 100%;
    overflow: scroll;
}

.machine-progress-body {
    padding-top: 2.84%;
    height: 92%;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    gap: 2.84%;
    width: 100%;
    margin-top: 20%;
    margin-bottom: 10%;
}

.substage-button-container {
    font-size: 12px;
    align-items: center;
    margin: 2% 0 2% 0;
    display: flex;
    background: #F0F5FF;
}

.mb-2 {
    margin-bottom: 2%;
}

.machine-stage-details {
    box-sizing: border-box;
    border-radius: 10px;
    padding: 6%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.active-stage {
    border: 1px solid #D5D8DD;
}

.inactive-stage {
    border: 1px solid #D5D8DD;
    opacity: 0.4;
}

.sub-station-button {
    width: 100%;
    height: 100%;
    background: #F0F5FF;
    border-radius: 6px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    padding: 3.5%;
    text-align: left;
}

.active-button {
    background: #CCDDFF;
}

.assembly-completion-button {
    width: 100%;
    height: 100%;
    background: #448AFF;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    padding: 3.5%;
}

.button-disabled {
    background: #CCCCCC;
    cursor: none;
    pointer-events: none;
}

.no-pointer-events {
    cursor: none;
    pointer-events: none;
}

.stage-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stage-completed {
    background-color: #16A220;
    padding: 0.5% 2% 0.5% 2%;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 28px;
    border-radius: 4px;
}

.sub-stage-completed {
    padding-right: 4%;
}

.img {
    vertical-align: middle;
}

.button-text {
    float: left;
}

.button-icon {
    float: right;
}

.machine-completion-button-container {
    height: 5.5%;
    min-height: 40px;
    position: fixed;
    /* top: 55%; */
    bottom: 1%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
    z-index: 1000;
}

.machine-completion-button {
    width: 100%;
    height: 100%;
    background: #448AFF;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {

    /* For desktop: */
    .machine-progress-body {
        margin-top: 20%;
    }
}