.react-table-container {
  margin-top: 2%;
}

.rc-table-cell {
  border: none;
}

.rc-table-content {
  border: none;
}

.rc-table th,
.rc-table td {
  border: none;
}

.rc-table thead td,
.rc-table thead th {
  text-align: left;
  background: #d5d8dd;
}

.rc-table td {
  padding: 10px 8px;
  border-bottom: 1px solid rgb(240, 236, 236);
}

.rc-table-thead {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #00173d;
}

.rc-table-tbody {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #00173d;
}

.status {
  font-family: "Poppins";
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  justify-content: center;
  width: 120px;
  height: 30px;
  background: #E1F4EB;
  border-radius: 4px;
}

.NOT_STARTED {
  background-color: #f4f5f6;
  color: #adb5c0;
}

.IN_PROGRESS {
  background-color: #fef7eb;
  color: #f1d071;
}

.COMPLETE {
  color: #60c395;
  background-color: #e1f4eb;
}

.DEFECT {
  color: #E68B72;
  background-color: #FAE6E1;
}

.rc-table table {
  border-spacing: 0px 0px;
}