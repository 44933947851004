.machine-consent-screen {
    height: 100%;
    font-size: 20px;
    width: 100%;
    /* background: #00173D; */
}

.machine-consent-screen-header {
    height: 8.8%;
    /* background-color: green; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6.8%;
    font-weight: 400;
    justify-content: space-between;
}

.heading {
    display: flex;
    width: 60%;
    justify-content: flex-start;
}

.consent-heading-text {
    margin-left: 10%;
}

.header-icons {
    display: flex;
    flex-direction: row;
    width: 20%;
    justify-content: space-between;
}

.machine-consent-screen-body {
    padding-top: 20%;
    height: 43%;
}

.machine-consent-screen-text-container {
    margin-top: 10%;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #00173D;
}

.machine-consent-screen-save-button-container {
    height: 10.5%;
    min-height: 80px;
    position: absolute;
    /* top: 55%; */
    bottom: 2%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.consent-checkbox-container {
    height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.consent-checkbox {
    height: 70%;
    width: 6%;
}

.consent-checkbox-text {
    text-align: center;
}

.consent-home-button {
    width: 100%;
    height: 45%;
    min-height: 46px;
    background: #448AFF;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

.button-disabled {
    background: #CCCCCC;
    cursor: none;
    pointer-events: none;
}

