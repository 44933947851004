.assembly-comlete {
    /* padding: 3.9%; */
    font-size: 20px;
    width: 100%;
    /* background: #00173D; */
}

.assembly-comlete-body {
    height: 80%;
    padding-top: 20%;
}

.complete-icon-container {
    /* margin-top: 60%; */
    padding-top: 20%;
    text-align: center;
}

.assembly-comlete-save-button-container {
    height: 5.5%;
    min-height: 42px;
    position: absolute;
    /* top: 55%; */
    bottom: 4%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
}

.save-button {
    width: 100%;
    height: 100%;
    background: #448AFF;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

