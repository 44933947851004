.machine-details {
    height: 100%;
    width: 100%;
    /* to incorporate the default padding of the main App class */
    background: #ffffff;
    padding-left: 0px;

}

.machine-details-header {
    padding-bottom: 12px;
}

.part-listing-body {
    height: 100%;
}

.machine-high-detail-container {
    margin-left: 4.6%;
    margin-top: 0.3%;
    background: #ffffff;
}

.machine-high-detail {
    display: flex;
    align-items: center;
    margin: 10px 20px 10px 0px
}

.machine-id-heading span {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 10px 10px 10px 0px;
}

.page-route-header {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.blue-span {
    color: #448AFF;
    cursor: pointer;
}

.page-route-header :first-child {
    color: #448AFF;
}

.machine-details-listing-body {
    height: 100%;
    /* background: red; */
    margin-left: 4%;
    padding: 2% 3%;
    border-radius: 8px;
    box-shadow: 5px 0px 20px rgba(0, 23, 61, 0.16);
    width: 92%;
}

.header-details-container {
    display: flex;
    justify-content: space-between;
    column-gap: 3%;
}

.common-machine-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    border: 1px solid #F4F5F6;
    border-radius: 10px;
    padding: 2px 10px;
    /* word-break: break-all; */
}

.common-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    color: #818a98;
}

.page-toggle-container {
    display: flex;
    justify-content: flex-start;
    margin-top: auto 30px;
}

.common-toggle-class {
    color: #ADB5C0;
    font-size: small;
    padding: 14px;
    align-content: center;
    justify-content: center;
    margin-right: 8px;
}

.common-toggle-class:hover {
    cursor: pointer;
}

.toggle-active {
    border: #448aff;
    background-color: #f0f5ff;
    color: #448aff;
    border-radius: 4px;
}

.parts-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 25px;
    row-gap: 10%;
    column-gap: 3%;
    justify-content: space-between;
}

.common-part-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #F4F5F6;
    border-radius: 5px;
    padding: 2px 10px;
    margin-left: 0px;
    justify-content: space-between;
    border-radius: 10px;
}

.common-edit-button {
    align-self: flex-end;
    color: #448AFF;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    line-height: 5px;
}

.common-part-id-class {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    padding: 0px;
}

.download-icon-class {
    flex-grow: 0.1;
    cursor: pointer;
    margin: auto 3px;
}

.delete-icon-class {
    flex-grow: 0.05;
    cursor: pointer;
    margin: auto 3px;
}

.hidden-div {
    flex-grow: 0.9;
}

.machine-status {
    line-height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 3px 8px;
    justify-content: center;
    width: 120px;
    height: 30px;
    border-radius: 4px;
    margin: auto 40px;
}

.timeline-class {
    margin: 10px;
}

.timeline-container {
    margin-top: 30px;
}

.parent-stage {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    /* border: 1px solid rgb(219, 215, 215); */
}

.stage-date-completion {
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    flex-grow: 0.08;
    line-height: 3;
    width: 70px;
    /* border: 1px solid rgb(219, 215, 215); */
}

.stage-completion-icon {
    justify-self: center;
    z-index: 5;
    /* border: 1px solid rgb(219, 215, 215); */

}

.stage-info-class {
    display: flex;
    flex-direction: column;
    font-size: small;
    border: 1px solid rgb(219, 215, 215);
    flex-grow: 2;
    background-color: #F4F5F6;
    line-height: 3;
    border-radius: 7px;
    padding-left: 10px;
    margin-left: 10px;
}

.stage-info-class-active {
    display: flex;
    flex-direction: column;
    font-size: small;
    border: 1px solid rgb(219, 215, 215);
    flex-grow: 2;
    background-color: #F4F5F6;
    line-height: 1.5;
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 5px;
    margin-left: 10px;
}

.stage-ele-time {
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    color: #818A98;
}

.parent-substage {
    display: flex;
    flex-basis: 100%;
    margin-top: 10px;
    /* border: 1px solid rgb(219, 215, 215); */
}

.substage-date-completion {
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 5;
    width: 95px;
    margin-right: 1%;
    /* border: 1px solid rgb(219, 215, 215); */
}

.substage-completion-icon {
    z-index: 5;
    width: 11px;
    /* border: 1px solid rgb(219, 215, 215); */
    line-height: 3;
}

.substage-info-class {
    flex-direction: column;
    font-size: small;
    border: 1px solid rgb(219, 215, 215);
    border-radius: 7px;
    padding-left: 10px;
    flex-grow: 2;
    line-height: 0.5;
    margin-left: 27px;
}

.substage-info-nulltime {
    flex-direction: column;
    font-size: small;
    border: 1px solid rgb(219, 215, 215);
    border-radius: 7px;
    padding-left: 10px;
    flex-grow: 2;
    line-height: 3.5;
    margin-left: 27px;
}

.substage-completion-time {
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    align-items: center;
    color: #818A98;
}

.timeline-line-class {
    position: absolute;
    margin-left: 5px;
    z-index: -1;
    transform: translate(0, -20%);
}

.timeline-line-stage-class {
    position: absolute;
    margin-left: 16px;
    z-index: -1;
}

.NOT_STARTED {
    background-color: #f4f5f6;
    color: #adb5c0;
}

.IN_PROGRESS {
    background-color: #fef7eb;
    color: #f1d071;
}

.COMPLETED {
    color: #60c395;
    background-color: #e1f4eb;
}

.DEFECT {
    color: #E68B72;
    background-color: #FAE6E1;
}

.blur {
    opacity: 0.8;
    z-index: 5;
    background: white;
    pointer-events: none;
    color: #adb5c0;
}