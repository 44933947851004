.assembly-timeline {
    padding: 3.9%;
    height: 100%;
    font-size: 20px;
    width: 92%;
    /* background: #00173D; */
}

.heading-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.heading-time {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

.timeline-stage {
    border-left: 1px solid #D5D8DD;
    padding-bottom: 3%;
}

.timeline-stage-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 0 3% 0;
}

.timeline-stage-name-container {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    width: 70%;
    display: flex;
    justify-content: flex-start;
}

.timeline-stage-icon {
    margin-left: -3%;
}

.timeline-stage-name {
    margin-left: 5%;
}

.timeline-stage-time {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}

.timeline-substage-name-container {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 20px;
}

.timeline-substage-name {
    margin-left: 5%;
}

.timeline-substage-time {
    font-size: 10px;
    line-height: 20px;
    color: #818A98;
}
.timeline-substage-data {
    display: flex;
    justify-content: space-between;
    margin-left: 6%;
    padding: 0 0 3% 0;
}

.blur-3 {
    opacity: 0.3;
}

.assembly-timeline-body {
    height: 75%;
    padding-top: 20%;
}

.assembly-body-heading {
    display: flex;
    justify-content: space-between;
    height: 10%;
}

.timeline-body-details {
    height: 90%;
}

.timeline-child {
    display: flex;
    align-items: normal;
    gap: 6%;
    height: 15%;
    align-items: start;
}

.timeline-child .timeline-icon:not(:last-child):after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #D5D8DD;
    /* left: 27%; */
    /* top: -1%; */
    height: 11%;
    margin-left: -12px;
    margin-top: 2px;
    z-index: -1000;
}

.timeline-name {
    font-size: 16px;
    font-weight: 600;
}

.date {
    font-size: 12px;
    line-height: 20px;
}

.timeline-child:last-of-type .timeline-icon::after {
    content: none;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .assembly-timeline-body {
        padding-top: 5%;
    }
}