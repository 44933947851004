table {
    border-spacing: 0px;
}

.custom-table-class {
    margin-top: 20px;
    width: 100%;
    font-size: 75%;
}

.column-data-class {
    display: contents;
    margin: 5px 3px;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.row-data-class {
    display: contents;
    width: 233px;
    height: 18px;
    padding: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.table-icons-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.excel-icon-class {
    font-size: 10%;
    width: 32px;
    height: 32px;
}

.row {
    align-items: center;
    padding: 10px;
    height: 60px;
}

.column-data-class tr {
    background-color: #D5D8DD;
    margin: 0px;
}

th {
    padding: 1.5% 1%;
}

td {
    padding: 3px 10px;
    vertical-align: middle;
    border-bottom: 1px solid #D5D8DD;
}

.column-data {
    display: flex;
    align-items: center;
    gap: 15px;
}

tr {
    border-bottom: black;
}