.order-details {
  height: 100%;
  width: 100%;
}


.po-listing {
  height: 96%;
  width: 102%;
  /* to incorporate the default padding of the main App class */
  background: #ffffff;
}

.po-listing-body {
  margin-left: 4.6%;
  height: 100%;
  background: #ffffff;
}

.po-listing-high-details {
  height: 8%;
  background: #ffffff;
  border-radius: 8px;
  min-height: 100px;
  padding: 0.8% 1.5%;
  box-shadow: 5px 0px 20px rgba(0, 23, 61, 0.16);
}

.po-listing-machine-details {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  font-size: small;
}

.po-listing-machine-text {
  font-size: small;
  color: #818a98;
}

.status-container {
  margin-right: 5%;
  text-align: center;
  font-size: smaller;
}

.status-text {
  margin-left: 10px;
  font-size: 14px;
  line-height: 21px;
}

.status-number {
  font-weight: 600;
  font-size: 22px;
  line-height: 42px;
}

.po-listing-details-body {
  height: 75%;
  /* background: red; */
  margin-top: 2%;
  padding: 2% 2% 2% 2%;
  border-radius: 8px;
  box-shadow: 5px 0px 20px rgba(0, 23, 61, 0.16);
}

.order-list-filter-upload-container {
  display: flex;
  justify-content: space-between;
}

.order-list-filter-container {
  display: flex;
  flex-direction: row;
  width: 40%;
}

.upload-button-container {
  padding: 8px 12px;
  background: #448aff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.upload-text {
  margin-left: 5px;
}

.machine-type-filter {
  margin-right: 2%;
}

.file-upload-popup-container {
  width: 50%;
  position: absolute;
  background: #ffffff;
  top: 30%;
  left: 24%;
  padding: 3%;
  border-radius: 10px;
  box-shadow: 5px 0px 20px rgba(0, 23, 61, 0.16);
  backdrop-filter: blur(8px);
}

.rc-table-tbody .production_order_id_class {
  color: #448aff;
  cursor: pointer;
}

.upload-button-container:hover {
  cursor: pointer;
}