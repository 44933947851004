.upload-container {
  display: inline;
}

.close-button-class {
  display: flex;
  justify-content: flex-end;
}

.close-button-class:hover {
  cursor: pointer;
}

h3 {
  text-align: left;
  margin-top: 0px;
}

.upload-type-container {
  display: flex;
  padding-bottom: 3%;
}

.upload-type-container span {
  margin-left: 10px;
}

.upload-prod {
  flex-grow: 0.3;
}

input {
  margin: 0px 3px 0px 0px;
}

.submit-btn {
  background-color: rgb(212, 212, 212);
  cursor: pointer;
  margin-top: 10px;
  height: 32px;
  width: 20%;
  text-align: center;
  line-height: 2;
  font-size: 16px;
  border-radius: 4px;
}