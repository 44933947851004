.resolve-defect {
    height: 100%;
    font-size: 20px;
    width: 100%;
}

.resolve-defect-body {
    padding-top: 2.84%;
    /* height: 92%; */
    display: flex;
    flex-direction: column;
    /* font-weight: 600; */
    font-size: 24px;
    line-height: 28px;
    gap: 2.84%;
    width: 100%;
    overflow: auto;
    padding-bottom: 50px;
    padding-top: 20%;
}

.resolve-defect-body-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    height: 5%;
    min-height: 30px;
    margin-bottom: 5%;
}

.resolve-defect-body-body {
    /* height: 90%; */
    margin-bottom: 8%;
    margin-top: 5%;
}

.defect-details-input-box {
    border: 1px solid #D5D8DD;
    box-sizing: border-box;
    border-radius: 8px;
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 8px;
    font-size: 18px;
    color: #00173D;
}

.resolve-defect-input-container {
    /* height: 35%; */
    height: 10%;
    min-height: 130px;
}

.resolve-defect-body-save-button-container {
    height: 42px;
    min-height: 42px;
    position: fixed;
    /* top: 55%; */
    bottom: 1%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
    z-index: 1000;
}

.input-description {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
}

.defect-save-button {
    width: 100%;
    height: 100%;
    background: #448AFF;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

.button-disabled {
    background: #CCCCCC;
    cursor: none;
    pointer-events: none;
}

.error-msg {
    font-size: 12px;
    line-height: 20px;
    color: #F90000;
}


.test-input-container {
    height: 9%;
    min-height: 65px;
    width: 98%;
}

.test-input-box {
    border: 1px solid #D5D8DD;
    box-sizing: border-box;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 8px;
    font-size: 16px;
    line-height: 28px;
    color: #818A98;
    min-height: 46px;
}

.resolve-select-container {
    height: 5%;
    min-height: 56px;
    margin-bottom: 5%;
}

.defect-input-container {
    height: 22%;
    margin-bottom: 6%;
    min-height: 115px;
}

.add-part-container {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    line-height: 28px;
    color: #448AFF;
    margin-bottom: 4%;
}

.defected-part {
    /* height: 34%; */
    height: 20%;
    min-height: 200px;
}

.defected-part-number {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .resolve-defect-body {
        padding-top: 5%;
    }
}

