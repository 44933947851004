.stage-header {
    height: 10%;
    top: 0%;
    width: 93%;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6.8%;
    font-weight: 400;
    justify-content: space-between;
    min-height: 70px;
    z-index: 1000;
    background: white;
}

.stage-heading {
    display: flex;
    width: 75%;
    justify-content: flex-start;
}

.stage-header-machine-name {
    margin-left: 6%;
}

.stage-header-icons {
    display: flex;
    flex-direction: row;
    width: 20%;
    justify-content: space-between;
}