.defect-details {
    font-size: 16px;
    line-height: 28px;
    color: #00173D;
    border: 1px solid #D5D8DD;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5%;
}

.defect-header {
    display: flex;
    justify-content: space-between;
}

.defect-detail-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

.defect-detail-description {
    margin: 3% 0 3% 0;
}

.defect-description-name {
    font-size: 12px;
    line-height: 20px;
    color: #818A98;
}

.defect-description-details {
    font-size: 16px;
    line-height: 28px;
    color: #00173D;
}

.resolved {
    background: #16A220;
    padding: 1% 4% 1% 4%;
    color: #FFFFFF;
    border-radius: 4px;
}

.resolve-cta {
    width: 100%;
    background: #448AFF;
    border-radius: 4px;
    color: #FFFFFF;
    text-align: center;
    padding: 1.5% 0 1.5% 0;
}