.web-header {
    /* background: red; */
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 23, 61, 0.16);
    /* border-radius: 24px 24px 0px 0px; */
    position: relative;
    top: -3%;
    left: 2%;
    width: 96%;
    height: 10%;
    min-height: 70px;
    padding: 0 2% 0 2%;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.header-text {
    display: flex;
    flex-grow: 2;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.web-header-data {
    display: flex;
    flex-grow: 0.9;
    flex-flow: column-reverse;
}

.error-message {
    color: red;
    font-size: x-small;
}

.web-header-user-name {
    font-size: 100%;
}

.web-header-icons-container {
    display: inherit;
    flex-basis: 55%;

    align-items: center;
    justify-content: space-between;
}

.web-header-icons {
    margin: auto 3px;
    max-height: 40px;
    font-size: 80%;
    border-radius: 4px;
}

.web-user-detail-container {
    padding: 0.8% 1%;
    /* padding: auto 3%; */
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    flex-grow: 0.3;
}

.down-arrow-container {
    margin-left: 0.1%;
}

.more-user-details-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: absolute;
    top: 81%;
    right: 2%;
    padding: 1.5%;
    z-index: 1000;
}

.more-user-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #00173D;
}

.more-user-mail {
    margin-top: 10%;
    font-size: 12px;
    line-height: 18px;
}

.more-user-role {
    margin-top: 10%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.more-user-sign-out {
    text-align: center;
    margin-top: 10%;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #448AFF;
    cursor: pointer;
}