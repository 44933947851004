.login-page {
    height: 100%;
    width: 100%;
    overflow: scroll;
}

.logo-container {
    height: 45%;
    text-align: center;
}

.logo-img {
    margin: 0 auto;
    height: 70%;
    display: block;
    min-height: 100px;
    padding-top: 10%;
}

.login-form {
    /* height: 45%; */
    margin-bottom: 60px;
}

.login-form-heading {
    margin-bottom: 10%;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.input-container {
    height: 9%;
    min-height: 65px;
    width: 100%;
    margin-bottom: 5%;
}

.login-checkbox-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5%;
    height: 15%;
}

.login-checkbox-text {
    margin-left: 5%;
}

.mobile-container {
    width: 50%;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: darkorange;
}

.submit-button-container {
    height: 6%;
    min-height: 50px;
    position: fixed;
    /* top: 55%; */
    bottom: 1%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
    z-index: 1000;
}

@media only screen and (min-width: 768px) {

    /* For desktop: */
    .logo-img {
        padding-top: 5%;
    }
}