.defect {
    height: 100%;
    font-size: 20px;
    width: 100%;
    overflow: scroll;
}

.defect-body {
    padding-top: 2.84%;
    height: 92%;
    display: flex;
    flex-direction: column;
    /* font-weight: 600; */
    font-size: 24px;
    line-height: 28px;
    gap: 2.84%;
    width: 100%;
    padding-bottom: 50px;
    padding-top: 20%;
}

.defect-body-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    height: 5%;
}

.defect-type {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 28px;
}

.defect-tag {
    background: #F7F7F8;
    padding: 3% 4% 3% 4%;
    color: #818A98;
    text-align: center;
    width: 40%;
}

.active-defect-type {
    background: #F0F5FF;
    color: #448AFF;
}

.add-defect-icon-container {
    position: absolute;
    bottom: 3%;
    right: 2%;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .defect-body {
        padding-top: 5%;
    }
}