.home-page {
    /* background-color: blue; */
    height: 100%;
}

.hamburger-logo-container {
    position: fixed;
    top: 3%;
    left: 5%;
}

.homepage-logo-container {
    height: 82%;
}

.home-logo-img {
    margin: 0 auto;
    height: 30%;
    display: block;
    min-height: 100px;
    padding-top: 60%;
}

.add-machine-container {
    margin-bottom: 5%;
}

.scan-machine {
    width: 100%;
    height: 100%;
    min-height: 42px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #448AFF;;
    font-weight: 500;
    font-size: 16px;
    color: #448AFF;
    cursor: pointer;
}

.sidebar-container {
    background-color: #FFFFFF;
    width: 75%;
    height: 91%;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px 10px 10px 0px;
    z-index: 1000;
    padding: 10% 4% 0% 6%;
}

.user-details-container {
    padding-bottom: 8%;
    border-bottom: 1px solid #D5D8DD;
}
.user-name {
    font-size: 16px;
    line-height: 28px;
}

.user-email {
    font-size: 12px;
    line-height: 20px;
    color: #818A98;
}

.link-container {
    font-size: 16px;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #D5D8DD;
    padding-top: 8%;
}

.link {
    margin-bottom: 5%;
}

.link-icon {
    margin-right: 15px;
}

.sign-out-container {
    position: absolute;
    bottom: 1%;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .home-logo-img {
        padding-top: 12%;
    }

    .add-machine-container {
        margin-bottom: 2%;
    }

    .sidebar-container {
        width: 20%;
        padding: 5% 4% 0% 2%;
    }

    .sign-out-container {
        bottom: 4%;
    }
}