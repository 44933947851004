.order-details {
  height: 96%;
  width: 102%;
  /* to incorporate the default padding of the main App class */
  background: #ffffff;
}

.order-details-header {
  padding-bottom: 30px;
}

.order-high-detail-container {
  margin-left: 4.6%;
  margin-top: 0.3%;
  height: 100%;
  background: #ffffff;
}

.order-details-route {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.order-high-detail {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 20px 0px;
}

.order-id-heading {
  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: auto 10px auto 0px;
  padding-right: 10px;
}

.order-details-listing-body {
  height: 75vh;
  margin-left: 4%;
  padding: 2% 2%;
  border-radius: 8px;
  box-shadow: 5px 0px 20px rgba(0, 23, 61, 0.16);
}

.header-details-container {
  display: flex;
  justify-content: space-between;
}

.header-text {
  font-size: 19px;
}

.common-container {
  flex-grow: 2;
  border: 0.5px solid rgb(195, 195, 195);
  border-radius: 5px;
  align-items: flex-start;
  padding: 10px;
  margin: 10px 0px;
  margin-left: 0px;
}

.common-heading {
  font-size: x-small;
  color: #818a98;
}

.page-toggle-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.common-toggle-class {
  color: #ADB5C0;
  font-size: small;
  padding: 14px;
  align-content: center;
  justify-content: center;
  margin-right: 8px;
}

.common-toggle-class:hover {
  cursor: pointer;
}

.toggle-active {
  border: #448aff;
  background-color: #f0f5ff;
  color: #448aff;
  border-radius: 4px;
}

.arrow-container {
  width: 30px;
  padding: 8px;
  background-color: #d5d8dd;
  margin-left: 40%;
  justify-content: center;
  border-radius: 4px;
}

.arrow-container img {
  padding-top: 30%;
  padding-left: 20%;
}

.hidden-div {
  flex-grow: 4;
}

.actions-container {
  display: flex;
}

.machine-details {
  padding-left: 0px;
}

.number-of-machines-container {
  margin: auto 10px;
}

.ele-sub-status {
  text-align: center;
  padding: 6px 10px;
  width: 50%;
  background: #F4F5F6;
  color: #818A98;
  font-size: 14px;
  border-radius: 4px;
}

.machine_id_class {
  cursor: pointer;
}