.assembly-sub-stage {
    /* padding: 3.9%; */
    height: 100%;
    font-size: 20px;
    width: 100%;
    /* overflow: scroll; */
    /* background: #00173D; */
}

.assembly-sub-stage-blur {
    padding: 3.9%;
    height: 100%;
    font-size: 20px;
    width: 92%;
    /* opacity: 0.8; */
    z-index: -1;
    /* background: #00173D; */
    background: rgb(0, 23, 61, 0.9);
    opacity: 0.8;
}

.blur {
    opacity: 0.8;
    z-index: -1;
    /* background: #00173D; */
    background: rgb(0, 23, 61, 0.9);
    pointer-events: none;
}

.blur-8 {
    opacity: 0.6;
    pointer-events: none;
}

.assembly-sub-stage-header {
    height: 8.8%;
    /* background-color: green; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6.8%;
    font-weight: 400;
    justify-content: space-between;
}

.substage-heading {
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.machine-name {
    margin-left: 10%;
}

.header-icons {
    display: flex;
    flex-direction: row;
    width: 20%;
    justify-content: space-between;
}

.assembly-sub-stage-body {
    padding-top: 2.84%;
    /* height: 92%; */
    display: flex;
    flex-direction: column;
    /* font-weight: 600; */
    font-size: 24px;
    line-height: 28px;
    gap: 2.84%;
    width: 100%;
    /* overflow: auto; */
    padding-bottom: 50px;
    margin-bottom: 60px;
    padding-top: 20%;
}

.assembly-sub-stage-body-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 5%;
}

.body-heading-description {
    font-size: 12px;
    line-height: 20px;
    margin-top: 3%;
}

.body-heading-configuration {
    display: flex;
    gap: 2%;
    margin-top: 4%;
    flex-wrap: wrap;
    gap: 15px;
}

.conf-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #D5D8DD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 2% 5% 2% 5%;
    ;
    align-items: center;
}

.conf-value {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

.conf-name {
    font-size: 10px;
    line-height: 28px;
}

.input-container {
    position: relative;
}

.scanner-icon {
    vertical-align: middle;
}

.assembly-sub-stage-save-button-container {
    height: 42px;
    min-height: 42px;
    position: fixed;
    /* top: 55%; */
    bottom: 1%;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: center;
    width: 92%;
    z-index: 1000;
}

.substage-save-button {
    width: 100%;
    height: 100%;
    background: #448AFF;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

.button-disabled {
    background: #CCCCCC;
    cursor: none;
    pointer-events: none;
}

.confirmation-popup {
    /* height: 30%; */
    width: 60%;
    position: absolute;
    background: #FFFFFF;
    top: 32%;
    left: 15%;
    padding: 6%;
    border-radius: 10px;
}

.confirmation-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

.confirmation-data {
    margin-top: 8%;
    display: flex;
    flex-flow: column;
    height: 41%;
    margin-bottom: 7%;
}

.date {
    color: #818A98;
    font-size: 12px;
    line-height: 20px;
}

.component {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
}

.assembly-popup-item {
    margin-bottom: 3%;
}

.confirm-cta {
    padding: 2% 11% 2% 11%;
    background-color: #448AFF;
    color: white;
}

.cancel-cta {
    padding: 2% 11% 2% 11%;
    /* background-color: #448AFF; */
    color: #448AFF;
}

.confirmation-cta {
    display: flex;
    flex-direction: row;
    /* width: 70%; */
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    align-items: center;
}

.error-msg {
    font-size: 12px;
    line-height: 20px;
    color: #F90000;
}

.test-checkbox-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3% 0 6% 0;
    border-bottom: 1px solid #D5D8DD;
    width: 98%;
}

.question-desc {
    font-size: 16px;
    line-height: 28px;
    width: 90%;
}

.test-double-checkbox-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4% 0 4% 0;
    border-bottom: 1px solid #D5D8DD;
    width: 98%;
}

.double-checkbox-question-desc {
    font-size: 16px;
    line-height: 28px;
    width: 55%;
}

.checkbox-container {
    /* width: 8%;
    height: 70%; */
    width: 22px;
    height: 25px;
}

.double-checkbox-container {
    /* width: 8%;
    height: 70%; */
    width: 20%;
    height: 25px;
    display: flex;
    flex-direction: row;
}

.test-checkbox {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.test-input-container {
    height: 9%;
    min-height: 65px;
    width: 98%;
}

.test-input-box {
    border: 1px solid #D5D8DD;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 46px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 8px;
    font-size: 16px;
    line-height: 28px;
    color: #818A98;
}

.datePicker-custom {
    height: 10%;
}

.react-datepicker-wrapper {
    border: none;
    height: 100%;
    width: 100%;
}

.react-datepicker__input-container {
    height: 100%;
}

.react-datepicker__input-container>input {
    display: block;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid #D5D8DD;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FFFFFF;
    color: #818A98;
    padding: 4%;
}

.calendar-container {
    height: 8%;
    width: 98%;
    margin-bottom: 20px;
}

.checkbox-text {
    width: 50%;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
}

.not-ok {
    color: red;
}

.error-button {
    background: #F90000;
}

.cancel-cta,
.confirm-cta {
    cursor: pointer;
}

@media only screen and (min-width: 768px) {

    /* For desktop: */
    .assembly-sub-stage-body {
        padding-top: 15%;
    }
}